import {
  ActionIcon,
  Box,
  Center,
  Group,
  Table,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { AssetLogo } from 'components/AssetLogo';
import { TokenAmount } from 'components/TokenAmount';
import { TokenFiatPrice } from 'components/TokenFiatPrice';
import { AssetActionMenu } from 'components/pages/overview/Assets/AssetActionMenu';
import { useIsDesktop, useIsMobile } from 'hooks/useMedia';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { IoStar, IoStarOutline, IoSwapHorizontal } from 'react-icons/io5';
import { useFavoriteTokens } from 'recoil/favoriteTokens';
import { TokenBalance } from 'types/assets';
import { useAccount } from 'wagmi';

export function AssetRow({ asset }: { asset: TokenBalance }) {
  const { balance, erc20Id, isNative } = asset;
  const [showCrossChainButton, setShowCrossChainButton] = useState(false);
  const { t } = useTranslation();
  const moonChainKey = useMoonChainKeyParam();
  const { checkFavoriteToken, addFavoriteToken, removeFavoriteToken } =
    useFavoriteTokens();
  const { isConnected } = useAccount();

  const isFavorite = checkFavoriteToken(erc20Id);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const { other } = useMantineTheme();

  function renderFavoriteStar() {
    if (isNative) {
      return null;
    }

    return isFavorite ? (
      <ActionIcon
        title={t('icons.removeFromFavorites')}
        onClick={() => removeFavoriteToken(erc20Id)}
      >
        <IoStar />
      </ActionIcon>
    ) : (
      <ActionIcon
        title={t('icons.addToFavorites')}
        onClick={() => addFavoriteToken(erc20Id)}
      >
        <IoStarOutline />
      </ActionIcon>
    );
  }

  useEffect(() => {
    if (isMobile) {
      setShowCrossChainButton(true);
    }
  }, [isMobile]);

  return (
    <Table.Tr
      onMouseEnter={() => setShowCrossChainButton(true)}
      onMouseLeave={() => setShowCrossChainButton(false)}
      style={{
        fontSize: 'sm',
      }}
    >
      <Table.Td style={{ paddingRight: 0 }}>
        <Center>{renderFavoriteStar()}</Center>
      </Table.Td>
      <Table.Td style={{ paddingLeft: 0, paddingRight: 0 }}>
        <AssetLogo
          p={2}
          size={isDesktop ? 40 : 30}
          assetKey={balance.key}
          mx={'auto'}
        />
      </Table.Td>
      <Table.Td>
        <Text fw={'bold'} w={54} display={'inline-block'}>
          {balance.originSymbol}
        </Text>{' '}
        {/* <Text span mr={8}>
          {originChain}
        </Text> */}
      </Table.Td>
      <Table.Td>
        {isConnected ? (
          <TokenAmount
            amount={balance.amount}
            decimals={balance.decimals}
            maxDecimals={4}
            symbol={balance.getSymbol()}
            isHidable
          />
        ) : (
          <Text pl={20}>{'-'}</Text>
        )}
      </Table.Td>
      <Table.Td>
        <TokenFiatPrice showPrice assetAmount={balance} />
      </Table.Td>
      <Table.Td>
        <TokenFiatPrice
          assetAmount={balance}
          isHidable={true}
          maxDecimals={2}
        />
      </Table.Td>
      <Table.Td>
        <Group justify={'right'} gap={'xs'}>
          <Box>
            {showCrossChainButton && (
              <Link
                href={{
                  pathname: '[moonChainKey]/xcm',
                  query: { moonChainKey, asset: balance.key },
                }}
              >
                <Tooltip label={'Cross-chain transfer'}>
                  <Box>
                    <ActionIcon c={other.colors.action}>
                      <IoSwapHorizontal size={'2rem'} />
                    </ActionIcon>
                  </Box>
                </Tooltip>
              </Link>
            )}
          </Box>
          <AssetActionMenu asset={asset} />
        </Group>
      </Table.Td>
    </Table.Tr>
  );
}
