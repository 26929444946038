import {
  ActionIcon,
  Box,
  Card,
  Center,
  Checkbox,
  Group,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { ConnectEvmWallet } from 'components/ConnectWallet/ConnectEvmWallet';
import { Pagination } from 'components/Pagination';
import { useAllAssetsBalancesWithFiat } from 'hooks/queries/useAssetsBalances/useAssetsBalances';
import { useIsDesktop, useIsMobile, useIsWideScreen } from 'hooks/useMedia';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { MdOutlineClear } from 'react-icons/md';
import { useUserSettings } from 'recoil/userSettings';
import { TokenBalance } from 'types/assets';
import { useAccount } from 'wagmi';
import { AssetRow } from './AssetRow';
import classes from './Assets.module.css';
import { ImportTokens } from './ImportTokens';

export function Assets() {
  const { t } = useTranslation();
  const { isConnected } = useAccount();
  const [filter, setFilter] = useState('');
  const [filteredAssets, setFilteredAssets] = useState<
    TokenBalance[] | undefined
  >();
  const [activePage, setActivePage] = useState(1);
  const [isFilteredByBalance, setIsFilteredByBalance] = useState(false);
  const allAssetsBalancesWithFiat = useAllAssetsBalancesWithFiat();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isWideScreen = useIsWideScreen();
  const { smallBalanceFilteringValue } = useUserSettings();

  useEffect(() => {
    setActivePage(1);

    if (!allAssetsBalancesWithFiat) {
      return;
    }

    const filterLower = filter.toLowerCase();
    const filtered = allAssetsBalancesWithFiat.filter(
      (asset) =>
        (asset.balance.originSymbol?.toLowerCase().includes(filterLower) ||
          asset.balance.key.toLowerCase().includes(filterLower)) &&
        (!isFilteredByBalance ||
          asset.tokenConversion >= smallBalanceFilteringValue),
    );

    setFilteredAssets(filtered);
  }, [
    allAssetsBalancesWithFiat,
    smallBalanceFilteringValue,
    filter,
    isFilteredByBalance,
  ]);

  const rows = useMemo(() => {
    if (!filteredAssets) {
      return null;
    }

    return filteredAssets
      .slice((activePage - 1) * 10, activePage * 10)
      .map((asset) => <AssetRow key={asset.erc20Id} asset={asset} />);
  }, [filteredAssets, activePage]);

  return (
    <div>
      <Group mb={'xs'} justify={'space-between'}>
        <Title order={2} size={'h4'} mt={'auto'} lh={1} fw={'normal'}>
          {'Assets'}
        </Title>
        <Group>
          <Pagination
            activePage={activePage}
            setActivePage={setActivePage}
            total={filteredAssets?.length || 0}
          />
          <ImportTokens />
          <TextInput
            value={filter}
            onChange={(event) => setFilter(event.currentTarget.value)}
            leftSection={<IoSearch />}
            rightSection={
              <ActionIcon aria-label={t('clear')} onClick={() => setFilter('')}>
                {filter && <MdOutlineClear />}
              </ActionIcon>
            }
            placeholder={'Search assets'}
            classNames={{
              root: classes.filterRoot,
              section: classes.filterIcon,
              input: classes.filterInput,
            }}
            aria-label={'Search assets'}
          />
        </Group>
      </Group>

      <Group mb={'xs'} justify={'flex-end'} gap={'xs'}>
        <Checkbox
          radius="4"
          checked={isFilteredByBalance}
          onChange={(e) => setIsFilteredByBalance(e.currentTarget.checked)}
        />
        <Text size={'xs'}>{'Hide small balances'}</Text>
      </Group>

      <Card
        withBorder
        p={0}
        mih={isDesktop ? 642 : 538}
        style={{ overflow: 'auto' }}
        mb={'md'}
      >
        <Box miw={624}>
          <Table
            className={classes.table}
            verticalSpacing={'xs'}
            px={'xl'}
            fz={isMobile ? 'xs' : 'sm'}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th
                  style={{ width: isWideScreen ? '4%' : '6%' }}
                ></Table.Th>
                <Table.Th
                  style={{ width: isWideScreen ? '4%' : '8%', padding: 0 }}
                >
                  {'Token'}
                </Table.Th>
                <Table.Th style={{ width: '22%' }}></Table.Th>
                <Table.Th style={{ width: '18%' }}>{'Balance'}</Table.Th>
                <Table.Th style={{ width: '15%' }}>{'Token price'}</Table.Th>
                <Table.Th style={{ width: '15%' }}>{'Total value'}</Table.Th>
                <Table.Th style={{ width: isWideScreen ? '8%' : '16%' }}>
                  <Text ta={'right'} size={'sm'} fw={700}>
                    {'Actions'}
                  </Text>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
          {!isConnected && (
            <Stack pt={'lg'}>
              <Text ta={'center'}>
                {'Connect your wallet to check your assets balances'}
              </Text>
              <Center>
                <ConnectEvmWallet />
              </Center>
            </Stack>
          )}
        </Box>
      </Card>
    </div>
  );
}
