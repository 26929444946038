import { ActionIcon, Menu } from '@mantine/core';
import { useAddTokenToWallet } from 'hooks/useAddTokenToWallet';
import { useTranslation } from 'next-i18next';
import { IoClose, IoEllipsisVertical, IoWallet } from 'react-icons/io5';
import { useImportedTokens } from 'recoil/importedTokens';
import { TokenBalance } from 'types/assets';

export function AssetActionMenu({
  asset: { balance, erc20Id, isImported, isNative },
}: {
  asset: TokenBalance;
}) {
  const { t } = useTranslation();
  const { addToWallet, canAddTokens } = useAddTokenToWallet();
  const { removeImportedToken } = useImportedTokens();

  const isInvisible = isNative && !isImported;

  return (
    <div style={{ visibility: isInvisible ? 'hidden' : 'visible' }}>
      <Menu shadow={'md'} position={'bottom-end'}>
        <Menu.Target>
          <ActionIcon title={t('icons.options')}>
            <IoEllipsisVertical />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {isImported && (
            <Menu.Item
              leftSection={<IoClose />}
              onClick={() => removeImportedToken(erc20Id)}
            >
              {t('importedTokens.removeToken')}
            </Menu.Item>
          )}
          {!isNative && (
            <Menu.Item
              disabled={!canAddTokens}
              leftSection={<IoWallet />}
              onClick={() =>
                addToWallet(erc20Id, balance.decimals, balance.getSymbol())
              }
            >
              {'Add to wallet'}
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
